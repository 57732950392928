import React, { Component } from "react";
import relationalConfig from "../../config/RelationalConfig";
import moment from "moment";
import isEqual from 'lodash.isequal';
import {
  formatDate,
  hideByAll,
  renameColName,
  reportMitarbeiterUebersicht,
  reportStandardCols,
  tableDefaultSort,
} from "../../config/TableViewConfig";
import {
  FIRMENKARTEI,
  MITARBEITER_UEBERSICHT,
  VORSORGEN,
} from "../../utils/TableConstanten";
import Pagination from "./pagination";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { convertToCsv } from "../../config/csvExport";
import ConfirmationDialog from "../ConfirmationDialog";
import ArchiveDialog from "../ArchiveDialog";
import { getData2Archive } from "../Archive/ArchiveHelper";
import withLogging from "../../logging/withLogging";

class Table extends Component {
  defaultValues = {
    filters: {},
    currentPage: 0,
    maxPages: 0,
    orderBy: "",
    sortOrder: "",
  };

  state = {
    filterFirmenId: global.filterFirmenId,
    showPagination: true,
    mailInPipeline: false,
    isConfirmationDialogOpen: false,
    csvData: [],
    ...this.defaultValues,
  };

  headers = {
    "Content-Type": "application/json",
    Authorization: global.auth.authToken.token,
  };

  relationTableAdd = {};

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.forwardPage = this.forwardPage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeItemsPerPage = this.changeItemsPerPage.bind(this);
    this.toggleConfirmDialog = this.toggleConfirmDialog.bind(this);
  }

  addQueryAttribute(query, name, value) {
    let nameValue = name + "=" + value;
    return (query.length === 0 ? "?" : query + "&") + nameValue;
  }

  validate(props) {
    if (!props) {
      props = this.props;
    }
    var { currentPage, orderBy, sortOrder, showFilter } = this.state;
    var filters = this.props.filters;

    //Default Table Sorting
    if (props.resourceName in tableDefaultSort) {
      let defaultSorting = tableDefaultSort[props.resourceName];
      orderBy = orderBy !== "" ? orderBy : defaultSorting.orderBy;
      sortOrder = sortOrder !== "" ? sortOrder : defaultSorting.sortOrder;
    }

    let additionalStateParams = {
      filters,
      currentPage,
      orderBy,
      sortOrder,
      showFilter,
    };
    let addQueryAttribute = this.addQueryAttribute;
    let query = "";

    //add pagination to query
    query = addQueryAttribute(query, "page", currentPage);
    query = addQueryAttribute(
      query,
      "size",
      window.localStorage.getItem("ipp")
    );

    if (props.search && props.search.length > 0) {
      query = addQueryAttribute(query, "s", props.search);
    }
    if (props.detailId) {
      // console.log('ke detail', props.detailId)
      query = addQueryAttribute(query, "s", props.detailId);
    }
    if (props.resourceName === "report") {
      //auf report seite ggf nach Farbe Filtern
      let storeValue = localStorage.getItem("detailSort");
      if (storeValue)
        query = addQueryAttribute(
          query,
          "filterby_naechster_termin",
          storeValue
        );
    }

    if (props.fields) {
      let tmp = "";
      props.fields.forEach((fieldName) => {
        tmp = tmp.concat(fieldName, ",");
      });
      query = addQueryAttribute(query, "fields", tmp);
    }

    if (orderBy && sortOrder) {
      query = addQueryAttribute(query, "orderBy", orderBy);
      query = addQueryAttribute(query, "sortOrder", sortOrder);
    }

    Object.keys(filters).forEach((propName) => {
      query = addQueryAttribute(
        query,
        "filterby_" + propName,
        filters[propName]
      );
    });

    // console.log('query', query);
    // let resId = props.relatedResourceId ? props.relatedResourceId : '';
    // console.log('res id', global.api_url + props.resourceName + resId + query,);

    this.setState({ filterFirmenId: global.filterFirmenId });

    this.relationTableAdd = {};
    this.headers = { ...this.headers, firmenid: global.filterFirmenId };

    let data = "";
    if (!this.props.report) {
      fetch(global.api_url + props.resourceName + query, {
        headers: this.headers,
      })
        .then((res) => res.json())
        .then((response) => {
          // console.log('/api/' + props.resourceName + query, response);

          data = "";
          if (response.totalPages > 0) {
            data = response.data;
            this.setState(
              {
                currentPage: response.currentPage,
                maxPages: response.totalPages,
                showPagination: true,
              },
              () => {
                global.emitter.emit("REFRESH_PAGINATION"); //refresh pagination ;)
              }
            );
          } else {
            data = response.data;
            this.setState({ showPagination: false });
          }

          let relationRequests = [];
          data = typeof data === "undefined" ? [""] : data;
          data.forEach((row) => {
            const rowID = row.id;
            Object.keys(row).forEach(async (propName) => {
              //wir suchen die Ralationen dazu
              switch (props.resourceName) {
                //Bei Mitarbeiter wollen wir nicht Firma, sonder Array von taetigkeiten anzeigen
                case "mitarbeiter":
                  //hole taetigkeits anstelle von firmenId
                  if (propName.indexOf("Id") !== -1 && row[propName]) {
                    // console.log(propName, 'ersetzen duzrch tätigekti')
                    let path = "report/mitarbeiter_zuordnung/" + rowID;
                    relationRequests.push(
                      (async () => {
                        await fetch(global.api_url + path, {
                          headers: this.headers,
                        })
                          .then((res) => res.json())
                          .then((data) => {
                            let colConfig =
                              relationalConfig["mitarbeiter_zuordnung"];
                            for (var [colName, colData] of Object.entries(
                              colConfig
                            )) {
                              let fieldValue = data.map((row) => {
                                return row[colData] ?? "";
                              });
                              // console.log('ke dat', colName, colData);
                              // props.schema.properties[colName] = colName;
                              if (!props.schema.properties[colName]) {
                                this.relationTableAdd[colName] = colName;
                              }
                              if (!row[colName]) {
                                row[colName] = fieldValue;
                              }
                            }
                            // })
                          });
                      })()
                    );
                  }
                  break;
                default:
                  if (propName.indexOf("Id") !== -1 && row[propName]) {
                    let relationalResource = propName.replace("Id", "");
                    let path = relationalResource + "/" + row[propName];
                    relationRequests.push(
                      (async () => {
                        // console.log('relational requests', '/api/' + path);
                        let res = await fetch(global.api_url + path, {
                          headers: this.headers,
                        });
                        let data = await res.json();
                        let colConfig = relationalConfig[relationalResource];
                        for (var [colName, colData] of Object.entries(
                          colConfig
                        )) {
                          let fieldValue = Array.isArray(colData)
                            ? colData
                                .map((propName) => data[propName])
                                .join(" ")
                            : data[colData];
                          // console.log('ke dat', colName, fieldValue);
                          // props.schema.properties[colName] = colName;
                          if (!props.schema.properties[colName]) {
                            this.relationTableAdd[colName] = colName;
                          }
                          if (!row[colName]) {
                            row[colName] = fieldValue;
                          }
                        }
                      })()
                    );
                  }
                  break;
              }
            });
          });

          Promise.all(relationRequests).then(() => {
            // console.log('set state', props.resourceName, { data, showFilter: props.showFilter });
            this.setState(
              { data, showFilter: props.showFilter, additionalStateParams },
              () => {
                this.setTableProperties(props);
              }
            );
          });
        });
    } else {
      // console.log('ke', this.props.detailId)
      fetch(global.api_url + props.resourceName + query, {
        headers: this.headers,
      })
        .then((res) => res.json())
        .then((response) => {
          // console.log('/api/' + props.resourceName + query)
          // console.log('set state', props.resourceName, { data, showFilter: props.showFilter });

          data = "";
          if (response.totalPages > 0) {
            data = response.data;
            this.setState(
              {
                currentPage: response.currentPage,
                maxPages: response.totalPages,
                showPagination: true,
                additionalStateParams,
              },
              () => {
                global.emitter.emit("REFRESH_PAGINATION"); //refresh pagination ;)
              }
            );
          } else {
            data = response.data;
            this.setState({ showPagination: false });
          }
          this.setState({
            data,
            showFilter: props.showFilter,
            additionalStateParams,
          }, () => this.setTableProperties(props));
        });
    }
    //csv Daten vorbereiten bei
    this.prepareCSVData(
      global.api_url,
      props.resourceName,
      query,
      this.headers
    );
  }

  componentDidMount() {
    this.validate(this.props);

    global.emitter.on("REFRESH", () => {
      this.validate(this.props);
    });
    global.emitter.on("RESET", (cb) => {
      this.setState(
        { ...this.defaultValues, showPagination: false, showFilter: false },
        () => cb()
      );
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps, this.props)) {
      this.validate(prevProps);
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  setTableProperties(props) {
    // Props ermitteln!
    let properties = Object.keys(props.schema.properties).filter(
      (propName) =>
        props.excludeFields.indexOf(propName) === -1 &&
        propName.toLowerCase() !== "id" &&
        propName.toLowerCase().indexOf("Id") === -1
    );
    // console.log('ke sch pro', properties)
    properties = [...properties, ...Object.keys(this.relationTableAdd)];
    // console.log('ke pro+', properties)

    //bei report standardfelder wenn keine gewählt sind
    if (this.props.report && properties.length === 0) {
      //wir nehmen als Props einfach die Props der standat felder, diese sind so erfasst.
      if (props.resourceName === MITARBEITER_UEBERSICHT) {
        properties = reportMitarbeiterUebersicht;
      } else {
        properties = reportStandardCols;
      }
    }
    if(!isEqual(properties, this.state.properties)){
      this.setState({ properties });
      props.setFilterProperties(properties);
    }
  }

  async deleteObject(e) {
    this.toggleConfirmDialog();

    let id = this.state.deleteRow;
    const res = await fetch(
      global.api_url + this.props.resourceName + "/" + id,
      { method: "DELETE", headers: this.headers }
    );
    let response = await res.json();
    if (!res.ok) {
      toast.error(response.message, {});
    } else if (res.ok) {
      toast.success(response.message, {});
    }

    this.validate(this.props);
  }

  /**
   * Archivieren von mitarbeiter Daten
   * @param {*} id
   */
  archiveObject(id) {
    this.toggleConfirmDialog();
    getData2Archive(this.state.deleteRow);
  }

  quickAction(resourceId, resourceName, clickIdx, rowData) {
    let data = { resourceId, resourceName, new: false };
    //noch keine Untersuchung angelegt, wir kommen aber mit zeilen link hier her.
    if (resourceId === null && resourceName === "untersuchungen") {
      // console.log('noch kein eUntersuchung angelegt, neu erstellen?')
      // console.log('state data?!', this.state.data[clickIdx])
      data = { ...data, new: true, dataSet: rowData };
    }
    global.emitter.emit("SHOW_MODAL", {
      data: data,
      onClose: () => {
        global.emitter.emit("REFRESH_" + resourceName.toUpperCase());
        global.emitter.emit("REFRESH_" + this.props.resourceName.toUpperCase());
      },
    });
  }

  nextPage() {
    var cp = this.state.currentPage + 1;
    // console.log('ketest next?', cp)
    this.setState({ currentPage: cp }, () => {
      global.emitter.emit("REFRESH");
    });
  }
  forwardPage() {
    var cp = this.state.currentPage - 1;
    // console.log('ketest for?', cp)
    this.setState({ currentPage: cp }, () => {
      global.emitter.emit("REFRESH");
    });
  }
  changePage(e) {
    let page = e.target.value - 1;
    // console.log('ketest page ', page);
    this.setState({ currentPage: page }, () => {
      global.emitter.emit("REFRESH");
    });
  }

  changeItemsPerPage(e) {
    // console.log('ketest', e.currentTarget.value)
    window.localStorage.setItem("ipp", e.currentTarget.value);
    this.setState({ currentPage: 0 }, () => {
      global.emitter.emit("REFRESH");
    });
  }

  mailStatus(e, row) {
    e.preventDefault();
    console.log("info", row);
  }

  mitarbeiterDetails(e, row) {
    e.preventDefault();
    // console.log(e.target.dataset.sort)
    window.location = "#vorsorgenkartei_details/" + row.id;
    localStorage.setItem("detailSort", e.target.dataset.sort);
  }

  prepareCSVData(api_url, resourceName, query, headers) {
    let neueQuery = query.replace(/page=0/g, "page=ALL");
    // console.log("prepare", neueQuery)
    fetch(api_url + resourceName + neueQuery, { headers: headers })
      .then((res) => res.json())
      .then((response) => {
        // console.log('/api/' + props.resourceName + query)
        // console.log(response.data);
        if (response.data !== undefined) {
          this.setState({
            csvData: convertToCsv(response.data, this.props.resourceName, [
              ...(localStorage.getItem("report_fields") === null
                ? reportStandardCols
                : JSON.parse(localStorage.getItem("report_fields"))),
              "untersuchungen.status",
            ]),
          });
        }
      });
  }

  toggleConfirmDialog() {
    this.setState({
      isConfirmationDialogOpen: !this.state.isConfirmationDialogOpen,
    });
  }

  render() {
    if (this.state.data && this.state.properties) {
      let propertyIndex = [];
      let headings = this.state.properties.map((propName, idx) => {
        propertyIndex[idx] = propName;
        let orderby = (
          <div
            style={{
              display: "inline-block",
              fontSize: "0.8em",
              marginLeft: "15px",
            }}
          >
            <i
              onClick={() => {
                this.setState({ orderBy: propName, sortOrder: "DESC" }, () =>
                  this.validate()
                );
              }}
              className="fa fa-arrow-down"
              style={{
                opacity:
                  this.state.orderBy &&
                  this.state.orderBy === propName &&
                  this.state.sortOrder === "DESC"
                    ? 1
                    : 0.5,
              }}
            ></i>
            <i
              onClick={() => {
                this.setState({ orderBy: propName, sortOrder: "ASC" }, () =>
                  this.validate()
                );
              }}
              className="fa fa-arrow-up"
              style={{
                opacity:
                  this.state.orderBy &&
                  this.state.orderBy === propName &&
                  this.state.sortOrder === "ASC"
                    ? 1
                    : 0.5,
              }}
            ></i>
          </div>
        );

        //ggf. passen wir den Namen der Spalte an
        let colname =
          propName.split(".").length === 2 ? propName.split(".")[1] : propName;

        //wir wollen die Tabelle als klasse
        let colclass =
          propName.split(".").length === 2 ? propName.split(".")[0] : "";
        if (colname in renameColName) {
          colname = renameColName[colname].label;
        } else {
          colname = global.capitalize(
            propName.split(".").length === 2
              ? propName.split(".")[1].replace("Id", "")
              : propName.replace("Id", "")
          );
        }

        //Spalte ausblenden wenn relations Id enthalten
        colclass += hideByAll.includes(propName) ? " d-none" : "";
        if (propName in this.relationTableAdd) {
          //keine optionen für relations Spalten
          return (
            <th
              key={"heading-" + idx}
              className="text-muted"
              style={{
                position: "relative",
                whiteSpace: "nowrap",
                paddingRight: 30,
              }}
            >
              {colname}
            </th>
          );
        } else if (
          this.props.resourceName === MITARBEITER_UEBERSICHT &&
          propName === "statistik"
        ) {
          //mitarbewiter übersicht keine Order reihenfolge
          return (
            <th
              key={"heading-" + idx}
              className={colclass}
              style={{
                position: "relative",
                whiteSpace: "nowrap",
                paddingRight: 30,
              }}
            >
              {colname}
              {orderby}
              {/* {filter} */}
            </th>
          );
        }
        return (
          <th
            key={"heading-" + idx}
            className={colclass}
            style={{
              position: "relative",
              whiteSpace: "nowrap",
              paddingRight: 30,
            }}
          >
            {colname}
            {orderby}
            {/* {filter} */}
          </th>
        );
      });

      if (!this.props.report) {
        //report nicht bearbeitbar
        headings.push(<th key={"heading-1000"} style={{ width: 40 }}></th>);
      }
      //CSV Export nur bei mitarbeiter und Vorsorgekartei / Übersicht
      if (
        this.props.resourceName === "mitarbeiter" ||
        this.props.resourceName === "report" ||
        this.props.resourceName === "report/mitarbeiter_uebersicht"
      ) {
        let currentDate = moment().format("YYYY-MM-DD");
        headings.push(
          <th key={"heading-csv"} style={{ width: 40 }}>
            <CSVLink
              data={this.state.csvData}
              separator={";"}
              filename={`${currentDate}-${this.props.resourceName}.csv`}
              target="_blank"
            >
              <i
                className="fas fa-file-csv"
                style={{
                  color: "#f47a37",
                  fontSize: "1.05rem",
                  fontWeight: "400 !important",
                }}
              ></i>
            </CSVLink>
          </th>
        );
      } else {
        headings.push(<th key={"heading-1001"} style={{ width: 40 }}></th>);
      }

      let list = this.state.data.map((row, idx) => {
        let cells = [];

        this.state.properties.forEach((prop) => {
          let index = propertyIndex.indexOf(prop);
          let handlers = {};
          let detailHandler = {};
          if (
            this.props.rowClicks === true &&
            this.props.resourceName !== MITARBEITER_UEBERSICHT
          ) {
            let resourceName = prop.split(".")[0];
            handlers.onClick = () =>
              this.quickAction(
                row[resourceName + ".id"],
                resourceName,
                idx,
                row
              );
          } else if (
            this.props.rowClicks === true &&
            this.props.resourceName === MITARBEITER_UEBERSICHT
          ) {
            if (prop !== "statistik") {
              detailHandler.onClick = () => {
                // console.log('ke test klick')
                localStorage.removeItem("detailSort");
                window.location = "#vorsorgenkartei_details/" + row.id;
              };
            }
          }

          //Report Table Infos
          if (this.props.report) {
            if (this.props.resourceName === MITARBEITER_UEBERSICHT) {
              if (prop === "statistik") {
                let max = row.red + row.yellow + row.green;
                let wred = ((row.red * 100) / max) * 1.15;
                let wyle = ((row.yellow * 100) / max) * 1.15;
                let wgre = ((row.green * 100) / max) * 1.15;

                // wgre = wgre === 0 ? wgre + 20 : wgre;
                //erweitern für margins
                wyle = wgre === 0 ? wyle : wyle + 5;
                wred = wyle === 0 ? wred : wred + 5;

                row[prop] = (
                  <div className="stat-wrapper">
                    <a
                      href="#vorsorgenkartei_details"
                      data-sort="green"
                      className="btn stat-green btn-sm"
                      aria-hidden={row.green === 0}
                      style={{ width: wgre + "px" }}
                      onClick={(e) => this.mitarbeiterDetails(e, row)}
                    >
                      {row.green}
                    </a>
                    <a
                      href="#vorsorgenkartei_details"
                      data-sort="yellow"
                      className="btn stat-yellow btn-sm"
                      aria-hidden={row.yellow === 0}
                      style={{ width: wyle + "px" }}
                      onClick={(e) => this.mitarbeiterDetails(e, row)}
                    >
                      {" "}
                      {row.yellow}
                    </a>
                    <a
                      href="#vorsorgenkartei_details"
                      data-sort="red"
                      className="btn stat-red btn-sm"
                      aria-hidden={row.red === 0}
                      style={{ width: wred + "px" }}
                      onClick={(e) => this.mitarbeiterDetails(e, row)}
                    >
                      {row.red}
                    </a>
                  </div>
                );
              }
            } else {
              if (prop === "untersuchungen.naechster_termin") {
                let next_date = moment(row[prop]);
                let in6m = moment().add(6, "M");
                let in2m = moment().add(2, "M");
                // console.log('ke date', next_date.format('MM/YYYY'), today.format('MM/YYYY'), next_date.isValid());
                row.class = "ampel red";
                if (next_date.isValid()) {
                  // console.log('ke', next_date.format('MM/YYYY'), in2m.format('MM/YYYY'));
                  if (in2m.isBefore(next_date)) {
                    row.class = "ampel yellow";
                    // console.log('ke', next_date.format('MM/YYYY'), in6m.format('MM/YYYY'));
                    if (in6m.isBefore(next_date)) {
                      row.class = "ampel green";
                      // console.log('ke green');
                    }
                  }
                }
              }
            }
          }

          // console.log('ke row', row);
          // console.log('exclude', this.props.excludeFields);
          if (this.props.excludeFields.indexOf(prop) === -1) {
            let propValue = row[prop] ? row[prop] : "";

            //wenn firmenkartei prüfen ob leer, wenn ja inhalt aus vorsorgeliste anzeigen
            if (prop.split(".")[0] === FIRMENKARTEI && !row[prop]) {
              // console.log('ke prop', propValue, prop)
              // console.log('ke val', row['vorsorgekartei.'+prop.split('.')[1]])
              propValue = row[VORSORGEN + "." + prop.split(".")[1]];
            }

            let dateiIcon = "";
            let lowerProp = prop !== "" ? prop.toLowerCase() : "";
            let kommentarTooltipp = {};
            if (
              lowerProp === "untersuchungen.kommentar" ||
              lowerProp === "kommentar"
            ) {
              if (Array.isArray(propValue)) propValue = propValue[0];
              kommentarTooltipp = {
                "data-toggle": "tooltipp",
                "data-placement": "top",
                title: propValue,
              };
              if (typeof propValue === "string") {
                //Nur die ersten 20 zeichen
                propValue = propValue.substring(0, 17) + "...";
              }
              //Datei anzahl badge
              if (row["datei.anzahl"] > 0) {
                let label = row["datei.anzahl"] === 1 ? " Datei" : " Dateien";
                dateiIcon = (
                  <span className="badge badge-secondary">
                    {row["datei.anzahl"] + label}
                  </span>
                );
              }
            }

            let classes = prop in this.relationTableAdd ? "text-muted" : "";
            classes += hideByAll.includes(prop) ? " d-none" : "";

            let tooltipp = {};
            if (this.props.report && this.props.resourceName === "report") {
              tooltipp = {
                "data-toggle": "tooltipp",
                "data-placement": "top",
                title: "klicken zum bearbeiten",
              };
            }

            //format propValue
            let checkProp =
              prop.split(".").length === 2 ? prop.split(".")[1] : prop;
            if (checkProp in formatDate) {
              // console.log('ketest', checkProp,propValue, moment(propValue).isValid())

              propValue = moment(propValue).isValid()
                ? moment(propValue).format(formatDate[checkProp])
                : "keine Untersuchung";
            }

            if (this.props.renderers[prop]) {
              // console.log('this.props.renderers', this.props.renderers, prop, row);
              propValue = this.props.renderers[prop](row);
            }

            cells[index] = (
              <td
                key={index}
                className={classes}
                {...handlers}
                {...detailHandler}
                {...kommentarTooltipp}
                {...tooltipp}
              >
                {propValue}
                {dateiIcon}
              </td>
            );
          }
        });

        if (!this.props.report) {
          //report nicht bearbeitbar
          cells.push(
            <td key={row.id + idx + 98}>
              <button
                className="btn btn-block btn-sm btn-default"
                onClick={() => {
                  this.props.onRowEdit(row.id);
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
            </td>
          );

          cells.push(
            <td key={row.id + idx + 99}>
              <button
                className="btn btn-block btn-sm btn-default"
                onClick={() => {
                  this.setState({ deleteRow: row.id }, () =>
                    this.toggleConfirmDialog()
                  );
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </td>
          );

          if (this.props.resourceName === "mitarbeiter") {
            //Mail
            cells.push(row.mailInfo);
          }
        }

        return (
          <tr data-id={row.id} className={row.class} key={idx}>
            {cells}
          </tr>
        );
      });

      let pagination = "";
      if (this.state.showPagination) {
        pagination = (
          <Pagination
            maxPages={this.state.maxPages}
            currentPage={this.state.currentPage}
            next={this.nextPage}
            forward={this.forwardPage}
            page={this.changePage}
            changeItemsPerPage={this.changeItemsPerPage}
          />
        );
      }

      let confirmationDialog = (
        <ConfirmationDialog
          isOpen={this.state.isConfirmationDialogOpen}
          title={`Bestätigung erforderlich`}
          message={`Bist du sicher, dass du diesen Eintrag undwiederruflich löschen möchtest?`}
          onConfirm={this.deleteObject.bind(this)}
          onClose={this.toggleConfirmDialog}
        />
      );

      if (this.props.resourceName === "mitarbeiter") {
        //Bei mitarbeiter darf nciht gelöscht werden!
        confirmationDialog = (
          <ArchiveDialog
            isOpen={this.state.isConfirmationDialogOpen}
            title={`Mitarbeiter löschen`}
            message={`Möchten Sie den Mitarbeiter wirklich löschen? <br><br>Oder soll er Archiviert werden?`}
            buttons={[
              {
                label: "Archivieren",
                onClick: this.archiveObject.bind(this),
                color: "primary",
              },
              {
                label: "Löschen",
                onClick: this.deleteObject.bind(this),
                color: "secondary",
              },
            ]}
            onClose={this.toggleConfirmDialog}
          />
        );
      }

      let tableClasses = this.props.rowClicks
        ? "table table-hover report"
        : "table table-hover";
      return (
        <div>
          <table className={tableClasses}>
            <thead>
              <tr>{headings}</tr>
            </thead>
            {list.length > 0 ? <tbody>{list}</tbody> : ""}
          </table>
          {list.length === 0 ? (
            <h3 className="m-5 text-center">Keine Daten vorhanden</h3>
          ) : (
            ""
          )}
          {pagination}
          {confirmationDialog}
        </div>
      );
    } else
      return (
        <div className="text-center m-5">
          <h3>Laden...</h3>
        </div>
      );
  }
}

export default withLogging(Table);
