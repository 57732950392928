import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TableHeader = ({ columns, order, sort, handleSortChange }) => {
  const [orderBy, setOrderBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    if (order && sort) {
      setOrderBy(order);
      setSortOrder(sort);
    }
  }, [order, sort]);

  const handleOrderBy = (dbName, order) => {
    setOrderBy(dbName);
    setSortOrder(order);
    handleSortChange(dbName, order);
  };

  const orderby = (column) => {
    const { dbName, label } = column;
    return (
      <>
        <span>{label}</span>
        <div
          style={{
            display: "inline-block",
            fontSize: "0.8em",
            marginLeft: "15px",
          }}
        >
          <i
            onClick={() => handleOrderBy(dbName, "DESC")}
            className="fa fa-arrow-down"
            style={{
              cursor: "pointer",
              opacity: orderBy === dbName && sortOrder === "DESC" ? 1 : 0.5,
            }}
          ></i>
          <i
            onClick={() => handleOrderBy(dbName, "ASC")}
            className="fa fa-arrow-up"
            style={{
              cursor: "pointer",
              opacity: orderBy === dbName && sortOrder === "ASC" ? 1 : 0.5,
            }}
          ></i>
        </div>
      </>
    );
  };

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <th key={column.dbName}>{orderby(column)}</th>
        ))}
        <th style={{ textAlign: "right" }}>CSV</th>
      </tr>
    </thead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dbName: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string,
    })
  ).isRequired,
  order: PropTypes.string,
  sort: PropTypes.oneOf(["ASC", "DESC"]),
  handleSortChange: PropTypes.func.isRequired
};

export default TableHeader;
