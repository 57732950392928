import React, { Component } from "react";
import isEqual from "lodash.isequal";
import Table from "./Table";
import PropTypes from "prop-types";
import {
  hideByAll,
  renameColName,
  tableColums,
} from "../../config/TableViewConfig";
import withLogging from "../../logging/withLogging";
import TableFilter from "./TableFilter";
import TableOld from "./TableOld";

class TableBox extends Component {
  state = { showFilter: false, filterProperties: null, filters: {} };

  validate(props) {
    // Perform deep equality check before setting state
    if (!isEqual(this.state, props)) {
      this.setState({ ...props });
    }
  }

  componentDidMount() {
    this.validate(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // Use deep equality check for props and state
    if (!isEqual(prevProps, this.props) || !isEqual(prevState, this.state)) {
      this.validate(this.props);
    }
  }

  setFilter = (values) => {
    if (!isEqual(this.state.filters, values)) {
      this.setState({ filters: values });
    }
  };

  //FIXME DELETE OLD TABLE SUPPORT
  setFilterProperties = (properties) => {
    const updatedProperties = properties
      .filter((property) => !hideByAll.includes(property))
      .reduce(
        (acc, property) => [
          ...acc,
          renameColName[property] ? renameColName[property].label : property,
        ],
        []
      );
    // console.log(updatedProperties);
    this.setState({ filterProperties: updatedProperties });
  };

  handleRowEdit = (id) => {
    global.emitter.emit("SHOW_MODAL" + (global.modal ? "_FALLBACK" : ""), {
      data: { resourceId: id, resourceName: this.state.resourceName },
      onClose: () => {
        global.emitter.emit("REFRESH_TABLE_DATA");
      },
    });
  };


  shouldComponentUpdate(nextProps, nextState) {
    const propsChanged = !isEqual(this.props, nextProps);
    const stateChanged = !isEqual(this.state, nextState);

    return propsChanged || stateChanged;
  }

  render() {
    if (this.state.resourceName && this.state.schema) {
      return (
        <section className="col p-0 connectedSortable tableBox">
          <div className="align-items-center">
            <div className="row">
              <div className="card-tools col">
                {this.props.disableFilter && (
                  <TableFilter
                    filterFields={tableColums[this.state.resourceName] || []}
                    setFilter={this.setFilter}
                  />
                )}
              </div>
              {!this.props.disableSearch && (
                <input
                  type="text"
                  name="table_search"
                  className="form-control float-right"
                  placeholder="Suche"
                  onChange={(e) => {
                    clearTimeout(this.searchtimer);
                    this.searchtimer = setTimeout(() => {
                      this.props.onSearch(e.target.value);
                    });
                  }}
                />
              )}
            </div>
            <div className="card-select-box row mt-3">
              <div className="col">{this.props.toolBox}</div>
            </div>
          </div>
          <div className="card">
            <div
              className="card-body"
              style={{ maxWidth: "100%", overflowX: "auto" }}
            >
              {!this.state.resourceName.startsWith("report") ? (
                <Table
                  apiEndpoint={this.state.resourceName}
                  rowClicks={this.props.rowClicks ?? false}
                  filters={this.state.filters}
                  onRowEdit={this.handleRowEdit}
                />
              ) : (
                <TableOld
                  resourceName={this.state.resourceName}
                  detailId={this.props.detailId}
                  relatedResourceId={this.props.relatedResourceId}
                  schema={this.state.schema}
                  ref={this.tableRef}
                  search={this.state.search}
                  fields={this.state.fields}
                  filters={this.state.filters}
                  rowClicks={
                    this.props.rowClicks ? this.props.rowClicks : false
                  }
                  excludeFields={
                    this.props.excludeFields ? this.props.excludeFields : []
                  }
                  renderers={this.props.renderers ? this.props.renderers : {}}
                  onRowEdit={this.handleRowEdit}
                  report={this.state.report}
                  setFilterProperties={this.setFilterProperties.bind(this)}
                />
              )}
            </div>
          </div>
        </section>
      );
    } else {
      return <span></span>;
    }
  }
}

TableBox.propTypes = {
  disableSearch: PropTypes.bool,
  disableFilter: PropTypes.bool,
  resourceName: PropTypes.string.isRequired,
  detailId: PropTypes.string,
  relatedResourceId: PropTypes.string,
  toolBox: PropTypes.object,
  rowClicks: PropTypes.bool,
  excludeFields: PropTypes.array,
  renderers: PropTypes.object,
};

TableBox.defaultProps = {
  disableSearch: true,
  disableFilter: true,
};

export default withLogging(TableBox);
