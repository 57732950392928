import React, { Component } from "react";
import PropTypes from "prop-types";
import { deleteApi, fetchApi } from "../../utils/DatabaseHelper";
import { tableColums, tableDefaultSort } from "../../config/TableViewConfig";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import LoadingSpinner from "../../utils/Loading";
import isEqual from "lodash.isequal";
import ConfirmationDialog from "../ConfirmationDialog";
import { toast } from "react-toastify";
import { getData2Archive } from "../Archive/ArchiveHelper";
import ArchiveDialog from "../ArchiveDialog";

class Table extends Component {
  defaultValues = {
    currentPage: 0,
    totalPages: 0,
    orderBy: "",
    sortOrder: "",
    loading: true,
    error: null,
    columns: [],
    data: [],
  };

  state = {
    currentPage: 0,
    totalPages: 0,
    orderBy: undefined,
    sortOrder: undefined,
    loading: true,
    error: null,
    columns: [],
    data: [],
    hasMore: false,
    isConfirmationDialogOpen: false,
    isArchivDialogOpen: false,
  };

  componentDidMount() {
    global.emitter.on("REFRESH_TABLE_DATA", this.fetchData);
    global.emitter.on("RESET", this.handleReset);
    window.addEventListener("scroll", this.handleScroll);

    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      this.fetchData(false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    global.emitter.off("REFRESH_TABLE_DATA", this.fetchData);
    global.emitter.off("RESET", this.handleReset);
  }

  handleReset = (cb) => {
    this.setState(
      { ...this.defaultValues, showPagination: false, showFilter: false },
      () => cb()
    );
  };

  fetchData = (append = false) => {
    const { apiEndpoint, filters } = this.props;

    let defaultSorting = tableDefaultSort[apiEndpoint] ?? {};
    let query = {
      ...filters,
      orderBy: this.state.orderBy ?? defaultSorting.orderBy ?? undefined,
      sortOrder: this.state.sortOrder ?? defaultSorting.sortOrder ?? undefined,
      currentPage: append ? this.state.currentPage : 0,
    };
    fetchApi(apiEndpoint, "", query)
      .then((resp) => {
        const columns = tableColums[apiEndpoint] || [];
        this.setState((prevState) => ({
          ...prevState,
          data: append ? [...prevState.data, ...resp.data] : resp.data,
          loading: false,
          columns,
          orderBy: query.orderBy,
          sortOrder: query.sortOrder,
          currentPage: resp.currentPage + 1,
          totalPages: resp.totalPages,
          hasMore: resp.currentPage + 1 < resp.totalPages,
        }));
      })
      .catch((error) => {
        this.setState({ error, loading: false });
      });
  };

  handleSortChange = (orderBy, sortOrder) => {
    this.setState({ orderBy, sortOrder, currentPage: 1, data: [] }, () =>
      this.fetchData()
    );
  };

  handleScroll = () => {
    const { loading, hasMore } = this.state;
    if (loading || !hasMore) return;

    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      this.setState({ loading: true }, () => this.fetchData(true));
    }
  };

  toggleConfirmDialog(id) {
    this.setState({
      isConfirmationDialogOpen: !this.state.isConfirmationDialogOpen,
      deleteRow: id,
    });
  }

  async deleteObject(e) {
    this.toggleConfirmDialog();

    deleteApi(this.props.apiEndpoint, this.state.deleteRow)
      .then((response) => {
        // console.log("delete:", response);
        toast.success(response.message, {});
        global.emitter.emit("REFRESH_TABLE_DATA");
      })
      .catch((err) => {
        console.err("Error", err);
        toast.error(err.message, {});
      });
  }


  toggleArchiveDialog(id) {
    this.setState({
      isArchivDialogOpen: !this.state.isArchivDialogOpen,
      archivObject: id,
    });
  }
  archiveObject() {
    this.toggleArchiveDialog();
    getData2Archive(this.state.archivObject);
  }

  render() {
    const { data, columns, loading, error, hasMore } = this.state;
    const { rowClicks, apiEndpoint } = this.props;

    if (loading && data.length === 0) {
      return <LoadingSpinner show={loading} />;
    }

    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <>
        <table
          className={
            rowClicks ? "table table-sm table-hover report" : "table table-sm"
          }
        >
          <TableHeader
            columns={columns}
            order={this.state.orderBy}
            sort={this.state.sortOrder}
            handleSortChange={this.handleSortChange}
          />
          <TableBody
            data={data}
            columns={columns}
            onDelete={this.toggleConfirmDialog.bind(this)}
            onEdit={this.props.onRowEdit}
            // {...(apiEndpoint === "mitarbeiter" && {  //FIXME Archiv geht auch nicht mehr, prüfen
            //   onArchive: this.toggleArchiveDialog.bind(this),
            // })}
          />
        </table>
        <span className="info" style={{ textAlign: "center" }}>
          {loading && <LoadingSpinner show={loading} />}
          {hasMore ? "" : "Alle Elemente wurden geladen."}
        </span>
        <ConfirmationDialog
          isOpen={this.state.isConfirmationDialogOpen}
          title={`Bestätigung erforderlich`}
          message={`Bist du sicher, dass du diesen Eintrag undwiederruflich löschen möchtest?`}
          onConfirm={this.deleteObject.bind(this)}
          onClose={this.toggleConfirmDialog.bind(this)}
        />
        <ArchiveDialog
          isOpen={this.state.isArchivDialogOpen}
          title={`Mitarbeiter Archivieren`}
          message={`Möchten Sie den Mitarbeiter Archivieren?`}
          buttons={[
            {
              label: "Archivieren",
              onClick: this.archiveObject.bind(this),
              color: "primary",
            },
            {
              label: "Abbrechen",
              onClick: this.toggleArchiveDialog.bind(this),
              color: "secondary",
            },
          ]}
          onClose={this.toggleArchiveDialog.bind(this)}
        />
      </>
    );
  }
}

Table.propTypes = {
  apiEndpoint: PropTypes.string.isRequired,
  rowClicks: PropTypes.any,
  filters: PropTypes.object,
  orderBy: PropTypes.string,
  sortOrder: PropTypes.string,
  onRowEdit: PropTypes.func.isRequired,
};

export default Table;
