import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { BsArchive, BsPencil, BsThreeDotsVertical, BsTrash } from "react-icons/bs";

const TableBody = ({ data, columns, onDelete, onArchive, onEdit }) => {

  const formatValue = (value, format) => {
    if (format && value) {
      return moment(value).format(format);
    }
    return value;
  };

  const getNestedValue = (obj, path) => {
    const parts = path.split('.');
    let current = obj;

    for (let part of parts) {
      if (Array.isArray(current)) {
        current = current.map(item => item[part]).filter(Boolean).join(', ');
      } else {
        current = current[part];
      }
      if (!current) break;
    }
    return current;
  };

  return (
    <tbody className="table-group-divider">
      {data.map((row, rowIndex) => (
        <tr key={row.id} >
          {columns.map((column) => (
            <td key={column.dbName}>
              {formatValue(getNestedValue(row, column.dbName), column.format)}
            </td>
          ))}
          <td style={{textAlign: "right"}}>
            <UncontrolledDropdown className="tableAction">
              <DropdownToggle color="secondary" className="tableActionButton" >
                <BsThreeDotsVertical />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => onEdit(row.id)}>
                  <BsPencil /> <span>Bearbeiten</span>
                </DropdownItem>
                {onArchive && 
                  (<DropdownItem onClick={() => onArchive(row.id)}>
                    <BsArchive /> Archivieren
                  </DropdownItem>)
                }
                <DropdownItem divider />
                <DropdownItem className="deleteItem" onClick={() => onDelete(row.id)}>
                  < BsTrash />Löschen
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dbName: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      format: PropTypes.string
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onArchive: PropTypes.func
};

export default TableBody;
