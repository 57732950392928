import React, { Component } from 'react';
import { hashToSchema, quickActions } from '../config/MenuConfig';
import QuickAction from './QuickAction';

class ContentHeader extends Component {
  state = { schema: {}, data: {}, title:''}

  validateData(props) {
    if (!props) {
      props = this.props;
    }
    this.setState(prevState => {
      // console.log("ke", prevState);
      props.title in hashToSchema && hashToSchema[props.title].label ? prevState.title = hashToSchema[props.title].label : prevState.title = global.capitalize(props.title);
      props.title in quickActions ? prevState.quickAction = quickActions[props.title] : prevState.quickAction = null;
      return prevState
    }) 

  }

  componentDidMount() {
    this.validateData(this.props);
  }
  
  componentDidUpdate(prevProps, prevState){
    if(prevProps !== this.props){
      this.validateData()
    }
  }

  quickAction(name) {
    try {
      global.emitter.emit('SHOW_MODAL', {
        data: { resourceName: name },
        onClose: () => {
          global.emitter.emit('REFRESH_TABLE_DATA');
        }
      });
    } catch (error) {
      console.error(`Failed to execute quickAction for resource: ${name}`, error);
    }
  }

  render() {
    return (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 align-items-center justify-content-between">
              <div className="col-sm-6">
                <h1 className="m-0">{ this.state.title }</h1>
              </div>
              <div className='col-auto'>
                <div id="quickActions" className="row row-cols-1">
                  {this.state.quickAction && (
                    <QuickAction title={this.state.quickAction.qaText} action={() => { this.quickAction(this.state.quickAction.qaTable); }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      
    );
  }
}

export default ContentHeader;
