import React, { Component } from 'react';
import { modalTitleRename } from '../config/TableViewConfig';
import Form from './Form';
import { IndividualForms, IndividualForm } from './Forms/individualForms/IndividualForm';
import { toast } from 'react-toastify';
import LoadingSpinner from "../utils/Loading";
import HistoryList from './Forms/HistoryList';


class Modal extends Component {
  state = { 
    schemas: {}, 
    schema: {}, 
    data: {}, 
    show: false,
    title: "Formular",
    alert: false,
    errors: {},
    showHistoryButton: false,
    showHistory: false,
    showLoading: true,
    canSave: false,
  }
  
  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.showHistoryButton = this.showHistoryButton.bind(this);
    this.setHistoryData = this.setHistoryData.bind(this);
    this.disableSaveButton = this.disableSaveButton.bind(this);

  }

  validate(props) {
    if (this.formRef.current) {
      this.formRef.current.forceUpdate();
    }
  }

  componentDidMount() {
    this.validate(this.props);
    global.emitter.on('SHOW_MODAL' + (this.props.eventSuffix ? this.props.eventSuffix : ''), (data) => {

      this.setState({info: data.data.new});
      this.sourceOnClose = data.onClose;
      this.formData = data.data;
      this.formData.schema = this.props.schemas[data.data.resourceName];
      this.setState({show: true});
      this.forceUpdate();
      if (!this.props.eventSuffix) {
        global.modal = true;
        this.base = true;
      }

      //Modal Title 
      if(this.formData.resourceName in modalTitleRename){
        // console.log('ketest', this.formData.resourceName, typeof modalTitleRename, modalTitleRename);
        this.setState({ title: modalTitleRename[this.formData.resourceName]})
      } else {
        this.setState({ title: global.capitalize(this.formData.resourceName) });
      }

      //nur bei untersuchungen soll speichern nicht gleich möglich sein
      this.setState({canSave: this.formData.resourceName === "untersuchungen"})
    })
  }

  componentDidUpdate(newProps){
    if(newProps !== this.props){
      this.validate(newProps)
    }
  }

  componentWillUnmount(){
    this.setState({canSave: false})
  }
  
  handleSaveClick = async () => {
    if (this.formRef && this.formRef.current) {
      try {
        this.canClose(await this.formRef.current.saveOrUpdate())
      } catch (err) {
        this.setState({errors: err})
      }
    } else {
      this.onClose();
    }
  }

  showHistoryButton(show){
    this.setState({showHistoryButton: show})
  }

  toggleHistorie(){
    this.setState({showHistory: !this.state.showHistory})
  }

  setHistoryData(data){
    this.setState({showLoading: false, historyData: data})
  }

  disableSaveButton(status = false){
    // console.log("saveBUtton", status)
    this.setState({canSave: status})
  }


  canClose(ret = undefined){
    // console.log("can?", ret)
    // console.log("canClose?", !ret || (ret && !ret.error), ret)
    if(!ret || (ret && !ret.error) ){
      global.emitter.emit('REFRESH_SCHEMA');
      this.onClose()
      toast.success("Erfolgreich gespeichert.")
    }else{
      toast.error(ret.error, {});
    }
  }

  onClose() {
    this.setState({show: false, errors: {}});
    if (this.formData) {
      delete this.formData;
      delete this.onClose;
      if (this.base) {
        delete global.modal;
      }
      if (this.sourceOnClose) {
        this.sourceOnClose();
      }
    }
  }

  render() {
    // console.log(this.formData)
    if (this.formData) {
      let form; 
      if(IndividualForms.includes( this.formData.resourceName )){
        form = <IndividualForm
          resourceId={this.formData.resourceId} 
          resourceName={this.formData.resourceName} 
          schemas={this.props.schemas} 
          schema={this.formData.schema} 
          ref={this.formRef} 
          helper = {this.formData.dataSet}
          formData={{}}
          functions = {{disableSaveButton: this.disableSaveButton, showHistoryButton: this.showHistoryButton, setHistoryData: this.setHistoryData}}
         />
      }else {
        form = <Form 
          resourceId={this.formData.resourceId} 
          resourceName={this.formData.resourceName} 
          schemas={this.props.schemas} 
          schema={this.formData.schema} 
          ref={this.formRef} 
          formData={{}} />;
      }

      let errorNotice = <div className='errorNotices'>{Object.keys(this.state.errors).map((key, index) => (
        <p key={index} className="errorNotice"><span>{`${this.state.errors[key].label}: `}</span>{`${this.state.errors[key].status}`}</p>
      ))}</div>

      let info = <div className="info-box alert alert-warning alert-dismissible fade show" role="alert">
            Daten wurden vorbelegt, bitte Prüfen.
            <button type="button" className="close" data-dismiss="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button> 
          </div>

      if(!this.state.info){
        info = ''
      }

      let modalHistory = <>
        <div className={`modal-history ${this.state.showHistory ? 'w100' : ''}`}>
          <div className={`modal-history-content ${this.state.showHistory ? '' : 'hide'}`}>
            <div className="modal-header">
              <h4 className="modal-title">Historie</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleHistorie.bind(this)} >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <LoadingSpinner show={ this.state.showLoading }/>
              { this.state.historyData ? <HistoryList liste={this.state.historyData} /> : ''}
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.toggleHistorie.bind(this)}>Zurück</button>
            </div>
          </div>
        </div>
      </>

      return (
        <div className={"modal fade " + (this.state.show ? 'show' : '')} id="modal-default" aria-modal="true" role="dialog" aria-hidden="true" style={this.state.show ? { paddingRight: 15, display: 'block' } : {}}>
          <div className="modal-dialog modal-dialog-scrollable modal-lg">
            <div className="modal-content">
              <div className={`content ${this.state.showHistory ? 'hide' : ''}`}>
                <div className="modal-header">
                  <h4 className="modal-title">{ this.state.title }: Erstellung oder Bearbeitung</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onClose.bind(this)} >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                  { info }
                <div className="modal-body">
                  {errorNotice}
                  {form}
                </div>
                <div className="modal-footer justify-content-between">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.onClose.bind(this)}>Abbrechen</button>
                  <button type="button" className={`btn btn-default ${this.state.showHistoryButton ? '' : 'hide'}`} onClick={this.toggleHistorie.bind(this)}><i className="fa fa-history" style={{marginLeft: "5px"}}/>Historie</button>
                  <button type="button" className="btn btn-primary" onClick={this.handleSaveClick} disabled={this.state.canSave}>Speichern</button>
                </div>
              </div>
              { this.state.showHistoryButton ? modalHistory : ''}
            </div>
          </div>
        </div>
      );
    } else {
      return <span></span>;
    }
  }
}

export default Modal;
